import React from 'react';
import { Router } from '@reach/router';
import EventDetail from '../components/routes/Ticket/EventDetail';

const CardPage = () => {
  return (
    <Router basepath="events">
      <EventDetail path="detail" />
    </Router>
  );
};

export default CardPage;
