import * as React from 'react';
import { Container, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import SEO from '../../../seo';
import { BusinessFooter, BusinessHeader, Header } from '../../../layouts';

const PageWrap = ({
  children,
  isWhitelabel,
  logoUrl,
  website,
  name,
  maxWidth = 'md',
}) => {
  return (
    <>
      <SEO title={`${name}`} />
      {isWhitelabel ? (
        <BusinessHeader
          logoUrl={logoUrl}
          merchantUrl={website}
          merchantPintunaUrl={website}
        />
      ) : (
        <Header logo />
      )}
      <Divider />
      <Container maxWidth={maxWidth} disableGutters>
        {children}
      </Container>
      <BusinessFooter />
    </>
  );
};
PageWrap.propTypes = {
  children: PropTypes.node.isRequired,
  isWhitelabel: PropTypes.bool.isRequired,
  logoUrl: PropTypes.string.isRequired,
  website: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  maxWidth: PropTypes.string.isRequired,
};

export default PageWrap;
